<template>
  <div :class="['layer', `${computedTheme}-css`]">
    <input :id="uuid" :type="computedTheme" @change="$emit('toggle')" :disabled="this.isDisabled" :checked="this.isVisible">
    <label :for="uuid">
      <svg viewBox="0 0 100 100">
        <path fill="none" stroke-width="20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.1 52.1l24.4 24.4 53-53"/>
      </svg>
      <span class="asset-name" :title="completeLabel">
        {{ abbreviatedLabel }}
        <b-icon v-if="assetHasErrors" icon='exclamation-triangle-fill' variant='danger'></b-icon>
        <b-button 
          v-if="assetHasErrors" 
          size="sm"
          class="remesh-stl-error-button"
          @click="remeshStl">
          remesh
        </b-button>
      </span>
    </label>
    <span class="loading-layer" v-if="this.isLoading"> <!-- loading indicator -->
      <span class="visually-hidden" role="alert" aria-busy="true">Loading...</span>
    </span>
    <span class="menu" v-else-if="!hideDeleteButton" ref="menu">⋮ <!-- menu button -->
      <div :class="{ 'hover-menu': true, active: isActive }">
        <button v-if="!hideDeleteButton" @click.prevent="$emit('delete')" value="delete">Delete</button>
        <b-dropdown v-if="canDownloadLayerData" dropleft text="Download" variant=none lazy toggle-class="input-button-dropdown" menu-class="input-button-dropdown-menu">
          <b-dropdown-item variant=none link-class="input-button-dropdown-item" v-for="outputFormat of this.layersetAssets[0].simulationResult.output_formats" :key=outputFormat.format @click="downloadAsset(outputFormat.format)">{{outputFormat.format}}</b-dropdown-item>
        </b-dropdown>  
      </div>
    </span>
    <!-- wireframe button -->
    <b-button id="wireframe-toggle" :title="wireframeOn ? 'show faces' : 'show wireframe'" class="py-1 wireframe-toggle" v-if="canUseWireframeButton" variant=outline  @click="toggleWireframe()">
      <b-icon-pentagon-fill class="mb-1" font-scale="0.9" v-if="wireframeOn"/>
      <b-icon-pentagon class="mb-1" font-scale="0.9" v-else />
    </b-button>
    
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fileDownloadService from '@/network/file-downloader';

export default {
  name: 'InputButton',
  props: {
    layersetAssets: {
      required: true
    },
    uuid: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    completeLabel: {
      type: String,
      required: true
    },
    abbreviatedLabel: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      required: true,
      validator: (theme) => ['checkbox', 'radio', 'hybrid-radio-checkbox'].includes(theme)
    },
    hideDeleteButton: {
      type: Boolean,
      required: false
    },
    layer: {
      required: true
    }
  },
  data() {
    return {
      isActive: false,
      wireframeOn: false
    };
  },
  computed: {
    isProjectInCompany() {
      return this.loggedInUser?.is_superuser || this.selectedProject?.company_id == this.loggedInUser?.company_id;
    },
    canUseWireframeButton() {
      return this.isGeometryLayer && (this.loggedInUser.is_superuser || this.userCompany?.is_rwdi);
    },
    isGeometryLayer() {
      return this.layersetAssets && this.layersetAssets.length > 0 && this.layersetAssets[0].simulationResult.layer_type.toLowerCase() === 'geometry';
    },
    computedTheme() {
      if (this.theme === 'radio' || this.theme === 'checkbox') {
        return this.theme;
      }
      return 'checkbox';
    },
    canDownloadLayerData() {
      if (!this.isProjectInCompany) {
        return false;
      }

      return this.hasModuleByName('download individual layer data');
    },
    assetHasErrors() {
      return this.layersetAssets[0]?.simulationResult?.errors?.length > 0;
    },
    ...mapGetters('project/simulationAsset', ['simulation']),
    ...mapGetters(['userCompany', 'loggedInUser', 'companySubscriptions']),
    ...mapGetters('project', ['selectedProject'])
  },
  created() {
    document.addEventListener('click', this.checkIfMenuIconClicked);
  },
  destroyed() {
    document.removeEventListener('click', this.checkIfMenuIconClicked);
  },
  methods: {
    hasModuleByName(moduleName) {
      let companyHasModule = !!this.userCompany?.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    toggleWireframe() {
      this.wireframeOn = !this.wireframeOn;
      this.$emit('toggleWireframe', this.layer);
    },
    async downloadAsset(outputFormatToDownload)  {
      for (let asset of this.layersetAssets) {
        if (asset.simulationResult?.output_formats) {
          let selected_format = asset.simulationResult.output_formats.find(x => x.format == outputFormatToDownload);
          let url = selected_format.path;
          let filename = selected_format.filename;
          await fileDownloadService.downloadFile(url, filename);
        }
      }
    },
    checkIfMenuIconClicked(element) {
      if (this.$refs.menu == null) {
        return;
      }
      (this.$refs.menu !== element.target && !this.$refs.menu.contains(element.target)) ? this.isActive = false : this.isActive = !this.isActive;
    },
    async remeshStl() {
      await this.$store.dispatch('project/simulationAsset/remeshStl', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        configurationId: Number(this.$route.params.configuration),
        simulationId: this.simulation.id,
        simulationAssetId: this.layersetAssets[0]?.simulationResult?.id
      });
      this.$router.go(0);
    }
  }
};
</script>

<style>
/* not sure why these bootstrap override classes need to be global instead of scoped but they do... */
.input-button-dropdown {
  background: none;
  border: none;
  text-align: left;
  font: normal normal normal 0.625rem/0.75rem Inter;
  letter-spacing: 0rem;
  color: #FFFFFF;
  opacity: 1;
  padding-top: 0;
}

.input-button-dropdown-menu {
  background: #00395E 0% 0% no-repeat padding-box;
}

.input-button-dropdown-item {
  text-align: left;
  font: normal normal normal 0.75rem/0.75rem Inter;
  color: #FFFFFF !important;
  opacity: 1;
  padding-top: 0;
  padding-right: 0;
  max-width: 85%;
  text-decoration: none !important;
}

.input-button-dropdown-item:hover {
  background-color: transparent !important;
}
</style>
<style scoped>
/* Scoped checkbox overrides */

.layers-panel .checkbox-css label {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  background-color: #fff;
  border-left: 0.25rem solid var(--grey-300);
  width: calc(100% - 1.5em); /* may need tweaking in context */
  font-size: 0.8em;
  transition: all 200ms ease-in-out;
  margin: 0;
}

.layers-panel .checkbox-css label span {
  margin-right: 1.5em;
  margin-top: 0;
  width: calc(100% - 4em);
}

.layers-panel .checkbox-css label:hover {
  border-left: 0.25rem solid var(--grey-500);
}

.layers-panel .checkbox-css input[type="checkbox"]:checked ~ label {
  border-left: 0.25rem solid var(--primary-cerulean);
}

.layers-panel .checkbox-css + .checkbox-css {
  margin-top: 0.5em;
}

.layers-panel .checkbox-css label:before {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.313rem;
  margin-right: 0.313rem;
}

.layers-panel .checkbox-css label > svg {
  left: 0.75rem;
  top: 0.5rem;
  width: 0.688rem;
  height: 0.688rem;
}

.layers-panel .layer.checkbox-css svg path {
  stroke-width: 1.563rem;
}

/* Scoped radio overrides */

.layers-panel .radio-css label {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  background-color: #fff;
  border-left: 0.25rem solid var(--grey-300);
  width: calc(100% - 1.5em); /* may need tweaking in context */
  font-size: 0.8em;
  transition: all 200ms ease-in-out;
  margin: 0;
}

.layers-panel .radio-css label span {
  margin-right: 1.5em;
  margin-top: 0;
  margin-left: 1.25em;
  width: calc(100% - 4em);
}

.layers-panel .radio-css label:hover {
  border-left: 0.25rem solid var(--grey-500);
}

.layers-panel .radio-css input[type="radio"]:checked ~ label {
  border-left: 0.25rem solid var(--primary-cerulean);
}

.layers-panel .radio-css + .radio-css {
  margin-top: 0.5em;
}

.layers-panel .radio-css label:before {
  margin-right: 0.313rem;
}

.layers-panel .radio-css label > svg {
  width: 0.688rem;
  height: 0.688rem;
}

.layers-panel .layer.radio-css svg path {
  stroke-width: 1.563rem;
}

/* Loading Layer */

.layer .loading-layer {
  position: absolute;
  z-index: 1;
  width: 0.9em;
  height: 0.9em;
  top: 0.4em;
  right: 0.8em;
  margin: 0;
}

.layer .loading-layer:after {
  content: '';
  width: 0.7em;
  height: 0.7em;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0.125rem var(--pastel-blue) solid;
  border-top: 0.125rem var(--primary-blue) solid;
  border-left: 0.125rem var(--primary-blue) solid;
  border-radius: 50%;
  animation: spCircRot 800ms infinite linear;
}

.asset-name {
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden
}

.radio-css > input[type="radio"], .radio-css > label::after, .radio-css > label::before {
  position: absolute;
  z-index: 1;
  border: 0.063rem solid var(--grey-400);
  height: 1.5em;
  width: 1.5em;
  top: 0.188rem;
  left: 0.5rem;
  cursor: pointer;
  border-radius: 100%;
  content: " ";
  transition: border-color .2s ease-in-out,box-shadow .2s ease-in-out;
}

.layer .menu {
  margin: 0;
  position: absolute;
  width: 1.1em;
  height: 1.1em;
  top: 0.313rem;
  right: 0.5rem;
  background-color: var(--grey-200);
  color: var(--grey-600);
  text-align: center;
  line-height: 1.1em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50%;
}

.wireframe-toggle {
  position: absolute;
  right: 0rem;
}

.menu .hover-menu {
  visibility: hidden;
  position: absolute;
  z-index: 3;
  top: -0.313rem;
  right: 0;
  background: var(--navy) 0% 0% no-repeat padding-box;
  border: 0.063rem solid var(--grey-300);
  background: #00395E 0% 0% no-repeat padding-box;
  border: 0.063rem solid #E2E6E9;
  border-radius: 0.313rem;
  opacity: 1;
}

.menu .hover-menu.active {
  visibility: visible;
}

.hover-menu button {
  margin: 0;
  background: none;
  border: none;
  color: var(--white);
  text-align: left;
  font: normal normal normal 0.625rem/0.75rem Inter;
  letter-spacing: 0rem;
  color: #FFFFFF;
  opacity: 1;
}



.remesh-stl-error-button {
  font-size: 0.563rem;
  padding: 0.125rem;
  margin-left: 0.313rem;
}
</style>