<template>
  <div class="d-flex flex-row justify-content-between downloadable-asset-container align-items-center">
    <label v-b-tooltip.hover :title='this.asset_name' class="downloadable-asset-label mb-0">{{this.truncatedName}}</label>
    <b-button small variant=light class="py-0 px-1 rounded-circle bg-gray" @click="downloadAsset" :disabled="downloading">
      <b-spinner small v-if="downloading" />
      <b-icon-download v-else scale="0.75"/>
    </b-button>
  </div>  
</template>

<script>
import fileDownloadService from '@/network/file-downloader';

export default {
  name: 'DataAsset',
  props: {
    assetObj: {
      required: true
    },
    
  },
  data() {
    return {
      downloading: false
    };
  },
  computed: {
    asset_name() {
      return this.assetObj?.filename;
    },
    truncatedName() {
      if (this.assetObj?.filename.length > 49) {
        return `${this.assetObj.filename.slice(0,46)}...`;
      }
      return this.assetObj?.filename;
    }
  },

  methods: {
    async downloadAsset()  {
      this.downloading = true;
      await fileDownloadService.downloadFile(this.assetObj.asset_file, this.assetObj.filename);
      this.downloading = false;
    },
  }
};
</script>

<style scoped>
.downloadable-asset-container {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  background-color: #fff;
  border-left: 0.25rem solid var(--grey-300);
  width: calc(100% - 1.5em); /* may need tweaking in context */
  font-size: 0.8em;
  transition: all 200ms ease-in-out;
  margin: 0;
  
}

.downloadable-asset-container {
  text-align: center;
}

.bg-gray {
  background-color: var(--grey-200);
}
</style>