<template>
<div class="d-flex flex-column h-100"> <!-- stack main contents on top of footer -->
  <header class="p-3">
    <h4>Add Layers</h4>
  </header>
  <main class="d-flex flex-row">  <!-- filter panel on left, table & associated data on the right -->
    <aside class="filter-pane pl-3 pt-3">  <!-- filter panel  -->
      <FormulateForm v-model="formValues" @input="handleInput" #default="{ }" class="filter-form">
        <h6 class="text-uppercase text-muted mb-2">Filter By</h6>
        <hr class="mb-2">
        <div visible v-for="input in layerPickerInputs" :key="input.title" id="input.title">
          <div class="float-right mr-2">  <!-- expand/collapse toggle -->
            <b-icon icon="caret-down-fill" v-if="visibleFilters.includes(input.title)" @click="toggleCollapse(input.title)"></b-icon>
            <b-icon icon="caret-left-fill" v-else @click="toggleCollapse(input.title)"></b-icon>
          </div>
          <!-- each FormulateInput is 1 filter type, consisting of a set of checkboxes for each filter value  -->
          <FormulateInput
            :name="`${input.title}`"
            :label="input.label"
            type="checkbox"
            :options="input.values"
            input-class="mb-0"
            outer-class="mb-0"
            :element-class="getCollapseClass(input.title)"
          />
          <div class="d-flex flex-row justify-content-end">
            <b-button variant="outline" size="sm" @click="clear(input.title)">Clear</b-button>
          </div>
          <hr class="mb-2">
        </div>
      </FormulateForm>
    </aside>
    <section class="d-flex flex-column pl-3 pt-3 w-100"> <!-- table and associated data.  3 rows stacked vertically -->
      <div class="layers-title">  <!-- row 1: # of matching layers row -->
        <span class="h6 ml-2">{{ matchingAssetsText }}</span><small class="ml-4 text-muted">Select to add to viewer</small>
      </div>
      <div class="layers-filter-badges my-1"> <!-- row 2: badges showing which filters are affecting the result set -->
        <div class="d-flex flex-row flex-wrap justify-content-start align-items-start">
            <b-badge v-for="filter in appliedFilterList" :key=filter.value class="p-2 m-1">
              <b-icon class="float-right" icon="x-circle-fill" @click="removeFilter(filter)"></b-icon>
              <span class="mr-2">{{filter.value}}</span>
            </b-badge>
          </div>
      </div>
      <div class="layers-table"> <!-- row 3: table containing the result set -->
        <b-table id="matchingLayersTable" selectable select-mode="multi" head-variant="light" small hover borderless :items="tableItems"
          :fields="this.tableFields" @row-selected="onRowSelected" ref="selectableLayerSetsTable">
          <!-- custom column definition for the 'Selected' column in the table header -->
          <template #head(Selected)> 
              <b-form-checkbox class="no-top-margin-all-descendants p-0" inline v-model="allRowsSelected" @change="toggleSelectAll"></b-form-checkbox>
          </template>
          <!-- custom column definition for the 'Selected' column in the table body -->
          <template #cell(Selected)="data"> 
              <b-form-checkbox class="no-top-margin-all-descendants p-0" disabled inline v-model="data.rowSelected"></b-form-checkbox> <!-- checkbox is disabled so that the table's row-selected event picks up any clicks instead of the checkbox -->
          </template> 
          <!-- Default collumn template in the table body for all other columns -->
          <template #cell()="data">  
            {{data.value}}
          </template>

        </b-table>
      </div>
    </section>
  </main>
  <footer class="p-3">
    <b-button variant="outline-secondary"  @click="$emit('close-form')">Cancel</b-button>
    <div class="float-right d-flex align-items-center">
      <span class="mr-4">{{this.selectedLayerSets.length}} layers selected</span>
      <b-button variant="primary" class="float-right" @click="$emit('layers-selected')">Add Layers</b-button>
    </div>
  </footer>
</div>
</template>
<script>
import {
  BButton,
  BTable,
  BIcon,
  BBadge,
  BFormCheckbox
} from 'bootstrap-vue';
export default {
  name: 'LayerPicker',
  components: {
    BButton,
    BTable,
    BIcon,
    BBadge,
    BFormCheckbox
  },
  props: {
    selectedLayerSets: {
      required: true
    },
    layerSetsMatchingGlobalFilter: {
      required: true
    },
    assetsMatchingGlobalFilter: {
      required: true
    },
    globalFilter: {
      required: true
    },
    layerPickerInputs: {
      required: true
    },
    appliedFilterList: {
      required: true
    }
  },
  computed: {
    tableItems() {
      return this.layerSetsMatchingGlobalFilter.map(layerSet => layerSet.filter);
    },
    globalFilterValues() {
      return Object.entries(this.globalFilter);
    },
    globalFilterKeys() {
      return Object.keys(this.globalFilter);
    },
    matchingAssetsText() {
      return `${this.layerSetsMatchingGlobalFilter.length} matching layers`;
    }
  },
  data() {
    return {
      formValues: {},
      visibleFilters: [], /* controls which filter panels are open.  By default all filter types will be added to this so they are all open by default */
      tableFields: [{key: 'Selected', thStyle: {width: '3.125rem'}}], /* controls the headers of the "selected layers" table.  This is dynamically built based on the global filter keys and 'Selected' is added mnaully in order to have a column for a checkbox that enables rows to be selected */
      allRowsSelected: false,  /* controls whether the "select all rows" checkbox selects of deselects all of the rows in the table */
      layerSetsToReselect: null
    };
  },
  mounted() {
    this.formValues = Object
      .keys(this.globalFilter)
      .reduce((obj, item) => {
        return {
          ...obj,
          [item]: []
        };
      }, {});

    this.globalFilterKeys.forEach(filterKey => {
      this.addTableField(filterKey);
      this.visibleFilters.push(filterKey);  //open the filter pane for this filter type by default
    });
  },
  methods: {
    setSelectedTableItems(layersToSelect) {
      //when the window opens, pre-select (in the table) any items that have been added to the selectedLayerSets collection
      layersToSelect.forEach(layer => {
        //foreach layer that's selected, find the matching row in the table and mark it as selected.
        let matchingRow = this.tableItems.findIndex(row => this.layerMatchesTableRow(row, layer.filter));
        if (matchingRow != -1) {
          this.$refs.selectableLayerSetsTable.selectRow(matchingRow);
        }
      });
    },
    layerMatchesTableRow(row, filter) {
      let matchesFilter = true;
      Object.keys(filter).forEach(prop => {
        if (filter[prop] !== row[prop]) {
          matchesFilter = false;
        }
      });
      return matchesFilter;
    },
    handleInput(data) {
      //when a filter value is added or removed the underyling list of layer sets that match the filter is changed and the table refreshes, causing the selectedLayerSets to be reset.
      //If a layerset was selected and is still present in the grid after the layerSet collection updates then it needs to be re-selected
      this.layerSetsToReselect = this.selectedLayerSets;  //save the copy of layerSets that need to be reselected.  They'll be reselected when in the tableItems watch
      this.$emit('setFilterValue', data);
    },
    clear(key) {
      this.formValues[key] = [];
      this.$emit('clearGlobalFilter', key);
    },
    removeFilter(filter) {
      this.formValues[filter.key] = this.formValues[filter.key].filter(filterValue => filterValue !== filter.value);
      /* there's no need to call an action on the store here as changing formValues will cause 'handleInput' to fire, which will then update to filter to remove the deleted item */
    },
    /* appending classes via this method allows us to target the 'element' part of the Vue Formulate form and turn it into a collapse (via Bootstrap) so that the
    FormulateInput's title shows but the 'options' list (e.g. the checkboxes) show/hide as the collapse is toggled.  This works in conjunction with the 'toggleCollapse'
    method to decide whether or not to append the 'show' class, which shows/hides the collapse control */
    getCollapseClass(inputTitle) {
      let display = this.visibleFilters.includes(inputTitle);
      return `collapse ${display ? 'show' : ''}`;
    },
    toggleCollapse(inputTitle) {
      if (this.visibleFilters.includes(inputTitle)) {
        this.visibleFilters = this.visibleFilters.filter(x => x != inputTitle);
      } else {
        this.visibleFilters.push(inputTitle);
      }
    },
    onRowSelected(items) {
      this.$store.dispatch('project/simulationAsset/setSelectedLayerSets', items);
      this.$nextTick(() => {
        this.allRowsSelected = this.layerSetsMatchingGlobalFilter.length === this.selectedLayerSets.length;
      });
    },
    toggleSelectAll() {
      /* this is a little counter-intuitive...  The checkbox in the table header is bound to allRowsSelected and it's click event fires this method.  Since the model
      binding happens before the click event is fired, allRowsSelected will be true if the user has just clicked to select all rows, so we need to call selectAddRows() if
      allRowsSelected is true (as opposed to it becomming true AFTER all rows are selected).  When it's false, then the user has just deselected the checkbox so selections need
      to be cleared.  It's implemented this way because it allows the checkbox to be bound to the allRowsSelected, which can change to false by deselecting a specific row, and
      then re-checking it will re-select all instead of deselecting all   */
      if (this.allRowsSelected) {
        this.$refs.selectableLayerSetsTable.selectAllRows();
      } else {
        this.$refs.selectableLayerSetsTable.clearSelected();
      }
      
    },
    addTableField(columnHeader) {
      var tableColumn = {
        key: columnHeader,
        sortable: true,
        thClass: 'border-right',
        tdClass: 'border-right'
      };

      this.tableFields.push(tableColumn);
    }
  },
  watch: {
    async tableItems() {
      //check to see if any layerSets are stored in the layerSetsToReselect and if so, try to reselect them, then set the set to null again
      await this.$nextTick();
      if (this.layerSetsToReselect) {
        this.setSelectedTableItems(this.layerSetsToReselect);
        this.layerSetsToReselect = null;
      }
    }
  }
};
</script>

<style>
/* this sets the background color on the disabled checkbox controls to white intead of grey so they don't look disabled.  They are disabled so that the table's rowSelected
event fires instead of the checkbox's 'selected' event.  The checkbox should appear as if it is not disabled */
#matchingLayersTable .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: white;

}
/* this sets the background on a disabled & checked checkbox to blue.  The checkmark in the middle of the checkbox is white so the white background hides it unless the 
background-color is changed when checked  */
#matchingLayersTable .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(1, 115, 185, 0.5);
}

</style>
<style scoped>

.layers-panel h4 {
  font-size: 1.25em;
}

header {
  /* height: 6.25rem!important; */  
  border-bottom: 0.125rem solid var(--grey-300);
}

main {
  height: calc(100% - 8.125rem); /* total height of the modal less the header and footer */
}

footer {
  /* height: 6.25rem; */
  border-top: 0.125rem solid var(--grey-300);
}

.layers-table {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 2;
}

.filter-pane {
  background-color: #E9ECEF;
  min-width: 17.5rem;
  overflow-y: auto;
}

/* hide the horizontal line below the checkboxes on the last filter key listed in the filter form */
.filter-form div:last-child hr {
  display: none;
}

</style>