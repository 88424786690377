class PresentationPlane {
  name: string;
  isVisible: boolean;

  constructor(name: string, isVisible: boolean) {
    this.name = name;
    this.isVisible = isVisible;
  }

}

export {
  PresentationPlane
};
