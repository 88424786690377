export const orderByKey = function (fieldName, descending) {
  //Django expects to __ notitation instead of . notation for accessing embedded objects
  return `${descending ? '-' : ''}${fieldName.replaceAll('.', '__')}`;
};

export const sortConfigurationLabels = function ( a, b) {
  if (a.configurationName < b.configurationName) {
    return -1;
  } else if (a.configurationName > b.configurationName) {
    return 1;
  } else {
    return 0;
  }
};