<template>
  <b-dropdown id="PresentationPlanesDropdown"  title="Toggle results for various presentation planes" class="mt-0" variant="light" size="sm" :disabled="this.isDisabled" v-if="presentationPlaneList.length > 1" no-caret>
    <template #button-content>
      <img class="presentation-surfaces-button" src="~@/assets/svg/icon-presentation-surfaces.svg" alt="Open">
    </template>
    <b-dropdown-group header="Presentation Planes" class="small no-top-margin-all-descendants">
      <b-dropdown-item-button class="plane" v-for="item in presentationPlaneList" :key="item.name" @click.native.capture.stop="togglePresentationPlane(item)" v-b-tooltip.hover="{ placement: 'top', title: item.name }">
        <b-icon v-if="item.isVisible" icon="check" aria-hidden="true"></b-icon>
        <b-icon v-else icon="blank" aria-hidden="true"></b-icon>
          {{item.name | truncate(43, '...')}}
        </b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
          <div class="d-flex">
            <b-dropdown-item-button class="clear-button" @click.native.capture.stop="clearAll()">Clear</b-dropdown-item-button>
            <b-dropdown-item-button class="select-all-button" @click.native.capture.stop="selectAll()">Select All</b-dropdown-item-button>
          </div>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownGroup,
  BDropdownItemButton,
  BDropdownDivider,
  BIcon
} from 'bootstrap-vue';
export default {
  name: 'PresentationPlaneDropdown',
  components: {
    BDropdown,
    BDropdownGroup,
    BDropdownItemButton,
    BDropdownDivider,
    BIcon
  },
  props: {
    presentationPlanes: {
      type: Array,
      required: true
    },
    layerType: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      presentationPlaneList: this.presentationPlanes
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    clearAll() {
      this.$emit('clear-all-presentation-planes', this.layerType);
    },
    selectAll() {
      this.$emit('select-all-presentation-planes', this.layerType);
    },
    togglePresentationPlane(item) {
      this.$emit('presentation-plane-toggled', this.layerType, item);
    }
  },
  watch: {
    presentationPlanes(newValue) {
      this.presentationPlaneList = newValue;
    }
  }
};
</script>

<style>
/* override widht=100% default so the clear/select-all buttons don't overlap */
 #PresentationPlanesDropdown .dropdown-item {
  width: auto;
}
</style>